#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

.antd-header-content-item {
  height: 100%;
  cursor: pointer;
}

.antd-header-content {
  float: right;
  padding: 0px;
  height: 100%;
}
